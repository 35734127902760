import React, { useState } from 'react';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';
import Anchor from '../components/Anchor';

import WeeklySchedule from '../components/WeeklySchedule';
import ClosedDayLabel from '../components/ClosedDayLabel';
import AccessInfoArea from '../components/AccessInfoArea';
import Modal from '../components/Modal';

import us1lg from '../assets/img/us-1-lg.png';

const About = () => {
  const { size } = useTrackedState();
  const [open, setOpen] = useState(false);
  return (
    <SubLayout topTitle="求人情報">
      <div>
        ヒラハタクリニックでは、現在以下の職種の募集を行っております。
        <br />
        求人に関するお問い合わせやご応募は{' '}
        <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>{' '}
        へお願いいたします。
        <Anchor id="jimu"></Anchor>
        <H3>医療事務（パートタイム）</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>受付・電話対応・予約管理、健康診断に関する事務など</li>
            <li>ダブルワークＯＫ</li>
            <li>
              内科、健康診断、人間ドックなど、働く人の健康管理を積極的に促進するクリニックです。
            </li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ul>
            <li>月・火・水・金8:30～21:00　木・土・日・祝日8:30～17:45</li>
            週3日以上勤務できる方。
            <br />
            1日4時間から応相談。
            <br />
          </ul>
          <h4>【給与】</h4>
          <ul>
            <li>時給　平日、土曜1,350円　日曜、祝日1,450円</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与あり（業績、規定による）</li>
            <li>交通費1日1,000円まで支給</li>
            <li>残業代は1分単位で支給</li>
            <li>制服貸与</li>
            <li>休憩ルームあり</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>年齢・学歴・資格不問</li>
            <li>医療事務の経験がある方（レセプト経験は問いません）</li>
            <li>基本的なパソコン操作ができる方</li>
          </ul>
          詳細については　
          <a href="mailto:keiri＠hirahata-clinic.or.jp">keiri@hirahata-clinic.or.jp</a>
          　までお問い合わせください。
        </div>
        <Anchor id="ns"></Anchor>
        <H3>看護師、准看護師(パートタイム)</H3>
        <div>
          <h4>【業務内容】</h4>
          <ul>
            <li>健康診断業務</li>
            <li>心電図、採血、血圧、眼底、脈波</li>
            <li>外来、点滴、内視鏡介助</li>
          </ul>
          <h4>【勤務時間・曜日】</h4>
          <ul>
            <li>土・日・祝　8:45～17:45（休憩60分）</li>
            <li>土曜勤務必須。日・祝は月１～2回程度</li>
          </ul>
          <h4>【給与】</h4>
          <ul>
            <li>正看護師：時給2,200円　准看護師：時給2,100円　※日・祝は+100円</li>
          </ul>
          <h4>【待遇・福利厚生】</h4>
          <ul>
            <li>賞与あり（業績、規定による）</li>
            <li>交通費1日1,000円まで支給</li>
            <li>残業代は1分単位で支給</li>
            <li>制服貸与</li>
            <li>休憩ルームあり</li>
          </ul>
          <h4>【応募条件・資格】</h4>
          <ul>
            <li>看證師免許・准看護師免許をお持ちの方</li>
            <li>看護師経験1年以上ある方</li>
            <li>ブランクある方歓迎</li>
            <li>Wワーク歓迎</li>
          </ul>
        </div>
      </div>
    </SubLayout>
  );
};

export default About;
